<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-4 ">
          <v-form ref="form" lazy-validation>
            <v-card-title>Modification Recitateur</v-card-title>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="recitateurInfo.name"
                    label="Titre"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <!--    <v-col cols="12" sm="12" md="6" v-if="recitateurInfo.name">
                  <vue-upload-multiple-image
                    style="margin-left: 160px;"
                    v-model="images"
                    :data-images="images"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Image Principale"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    :idUpload="imagesId"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-model="uploadPercentage"
                    v-if="showlowding"
                    style="max-width: 190px; margin-left: 160px;"
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentage) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>

                <v-col cols="12" sm="12" md="6"> </v-col> -->

                <v-col cols="12" sm="12" md="4" v-if="recitateurInfo.name">
                  <vue-upload-multiple-image
                    style="margin-left: 160px;"
                    v-model="images_BG"
                    :data-images="images_BG"
                    name="images"
                    drag-text="Veuillez ajouter une image Sans arrière-plan"
                    browse-text="Browse image"
                    primary-text="Image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccess_BG"
                    @before-remove="beforeRemove_BG"
                    :idUpload="images_BGId"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowding_BG && uploadPercentage_BG < 100"
                    style="max-width: 190px; margin-left: 160px;"
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentage_BG) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
              </v-row>
              <v-col cols="12" sm="12" md="6">
                <v-row align="center">
                  <v-col cols="auto" style="margin-top: 16px;">
                    <span>Statut Modification:</span>
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      v-model="recitateurInfo.isTranslated"
                      hide-details
                      inset
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="auto"
                    style="margin-top: 16px; margin-left: -20px;"
                  >
                    <span
                      style="color: #ff0000"
                      v-if="!recitateurInfo.isTranslated"
                    >
                      La modification est incomplète
                    </span>
                    <span
                      style="color: #00a325;"
                      v-if="recitateurInfo.isTranslated"
                    >
                      Modification Terminée
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      :disabled="
                        recitateurInfo.name.length <= 0 ||
                          recitateurInfo.imgSansBackgroundUrlId.length <= 0
                      "
                      class="info"
                      @click="dialogConfirmeEditRecitateur = true"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-col cols="12" sm="12" md="3">
      <v-btn @click="AddSuratDialogue = true" class="info  ml-6 mr-2 mt-4 ">
        Ajouter
      </v-btn>
    </v-col>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title>Liste Surat </v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :search="search"
        :loading="isLoadingTables"
        loading-text="Loading..."
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        :headers="headers"
        :items="recitateurInfo.recitateurSuratResponseList"
        class="elevation-1"
        item-key="id"
      >
        <template v-slot:item.suratLink="{ item }">
          <vuetify-audio
            style="height: 150px;
margin-top: 10px;
margin-bottom: 10px;"
            :file="item.suratLink"
            :autoPlay="false"
            :downloadable="false"
            :flat="false"
            :playIcon="icons.mdiPlay"
            :pauseIcon="icons.mdiPause"
            :stopIcon="icons.stopIcon"
            :refreshIcon="icons.mdiRefresh"
            :downloadIcon="icons.mdiDownload"
            :volumeHighIcon="icons.mdiVolumeHigh"
            :volumeMuteIcon="icons.mdiVolumeMute"
          ></vuetify-audio>
        </template>
        <template v-slot:item.isLocked="{ item }">
          <v-row>
            <v-switch
              :loading="item.isLoading"
              v-model="item.isLocked"
              hide-details
              inset
              @change="handleSwitchLocke(item)"
            ></v-switch>

            <v-icon
              color="primary"
              style="margin-top: 15px;"
              v-if="item.isLocked"
            >
              {{ icons.mdiLock }}
            </v-icon>
            <v-icon
              color="#00a325"
              style="margin-top: 15px;"
              v-if="!item.isLocked"
            >
              {{ icons.mdiLockOpen }}
            </v-icon>
          </v-row>
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="redirectToEditVersetStartTime(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>
        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="suppressionAction(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
        <template v-slot:item.isTranslated="{ item }">
          <v-row>
            <v-switch
              :loading="item.LoadingTranslated"
              v-model="item.isTranslated"
              hide-details
              :color="item.LoadingTranslated ? '#ff0000' : '#16b1ff'"
              inset
              @change="handleSwitchisTranslated(item)"
            ></v-switch>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="AddSuratDialogue" max-width="850">
      <v-card>
        <v-card-title class="text-h6">
          Ajouter une Sourate au Récitant
        </v-card-title>
        <v-row style="margin:20px">
          <v-col cols="6" sm="6" md="6">
            <v-select
              v-model="surat"
              clearable
              label="Surat"
              :items="suratArray"
              item-text="frenchName"
              item-value="suratId"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <label
              >Audio File
              <input
                type="file"
                accept="audio/*"
                @change="handleAudioUpload($event)"
              />
              <v-progress-linear
                v-if="showlowding_audio"
                style="max-width: 300px;
                margin-top: 10px;"
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </label>
          </v-col>
        </v-row>

        <v-row align="center" style="margin:20px">
          <v-col cols="auto" style="margin-top:20px">
            <span>Premium:</span>
          </v-col>
          <v-col cols="auto">
            <v-switch v-model="model" hide-details inset></v-switch>
          </v-col>
          <v-col cols="auto" style="margin-top: 16px; margin-left: -20px;">
            <v-icon color="#00a325" v-if="!model">
              {{ icons.mdiLockOpen }}
            </v-icon>
            <v-icon color="warning" v-if="model">
              {{ icons.mdiLock }}
            </v-icon>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1 error"
            text
            @click="AddSuratDialogue = false"
          >
            Annuler
          </v-btn>

          <v-btn
            :disabled="surat === null || fileId === null"
            color="info darken-1 "
            @click="ajoutSuratToRecitateur()"
            text
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteRecitateurSurat" max-width="530">
      <v-card>
        <v-card-title class="text-h6">
          Etes vous sur de vouloir supprimer le Surat Recitateur ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1 error"
            text
            @click="dialogDeleteRecitateurSurat = false"
          >
            Annuler
          </v-btn>

          <v-btn color="info darken-1 " text @click="deleteSuratRecitateur()">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog v-model="dialogConfirmeEditRecitateur" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Modifier le Recitateur ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogConfirmeEditRecitateur = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="UpdateRecitateur()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { VuetifyAudio } from "vuetify-audio";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import {
  mdiLockOpen,
  mdiLock,
  mdiPlay,
  mdiPause,
  mdiPlus,
  mdiStop,
  mdiRefresh,
  mdiDownload,
  mdiVolumeHigh,
  mdiVolumeMute,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
} from "@mdi/js";
import axios from "axios";
import Constant from "@/utils/constants";
import {
  getAllSurat,
  getAllVersetBySurate,
} from "@/views/qorani/services/services";
export default {
  components: { VueUploadMultipleImage, VuetifyAudio },
  data() {
    return {
      imageInfo: {
        fileName: "",
        fileUrl: "",
      },
      search: "",
      uploadPercentage_BG: null,
      fakePercentage_BG: null,
      uploadPercentage: null,
      fakePercentage: null,
      showlowding: false,
      showlowding_BG: false,
      showlowding_audio: false,
      imagesId: "0",
      images_BGId: "1",
      dialogConfirmeEditRecitateur: false,
      dialogVerset: false,
      idSuratSupprimmer: null,
      dialogDeleteRecitateurSurat: false,
      icons: {
        mdiLockOpen,
        mdiLock,
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiPlay,
        mdiPause,
        mdiStop,
        mdiRefresh,
        mdiDownload,
        mdiVolumeHigh,
        mdiVolumeMute,
      },
      suratRecitateur: {
        suratId: null,
        recitateurId: null,
        fileId: null,
        isLocked: false,
      },
      model: false,
      fileId: null,
      surat: null,
      suratArray: [],
      AddSuratDialogue: false,
      recitateurSuratArray: [],
      images: [],
      images_BG: [],
      isLoadingTables: false,
      recitateurInfo: {
        recitateurId: "",
        name: "",
        imgId: "",
        imageUrl: "",
        isTranslated: false,
        recitateurSuratResponseList: [],
        imgSansBackgroundUrl: "",
        imgSansBackgroundUrlId: "",
      },
      recitateur: {
        name: "",
        isTranslated: false,
        imgUrlId: "",
      },
      VersetBySurateArray: [],
      headers: [
        { text: "Surat en Arabe", value: "arabic_text", align: "center" },
        { text: "Surat en Français", value: "french_text" },
        { text: "Premium", value: "isLocked" },
        { text: "Traduction", value: "isTranslated" },
        { text: "File", value: "suratLink", align: "center" },
        { text: "Modifier", value: "modifier", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    const recitateurId = to.params.id;
    try {
      const response = await axios.get(
        `${Constant.QORANI}recitateur/get/${recitateurId}`
      );
      next((vm) => {
        vm.recitateurInfo = response.data;
        vm.recitateurInfo.recitateurSuratResponseList.forEach((item) => {
          item.isLoading = false;
          item.LoadingTranslated = false;
        });
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(null);
    }
  },
  methods: {
    async redirectToEditVersetStartTime(item) {
      this.$router.push({
        name: "VersetStartTime",
        params: {
          suratId: item.suratId,
          recitateurId: this.recitateurInfo.recitateurId,
        },
      });
    },

    async deleteSuratRecitateur() {
      const responseCategorie = await fetch(
        Constant.QORANI +
          `recitateur/removeSurat/${this.idSuratSupprimmer}/from/${this.recitateurInfo.recitateurId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDeleteRecitateur = false;
      }
    },
    async handleSwitchLocke(item) {
      item.isLoading = true;
      try {
        const response = await axios.get(
          Constant.QORANI + "recitateur/editRecitateurSuratItem/" + item.id
        );
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTimeout(() => {
          item.isLoading = false;
          window.location.reload();
        }, 3000);
      }
    },
    async handleSwitchisTranslated(item) {
      item.LoadingTranslated = true;
      try {
        const response = await axios.get(
          Constant.QORANI +
            "recitateur/editRecitateurSuratItemTranslated/" +
            item.id
        );
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTimeout(() => {
          item.LoadingTranslated = false;
          window.location.reload();
        }, 3000);
      }
    },
    suppressionAction(item) {
      this.idSuratSupprimmer = item.suratId;
      this.dialogDeleteRecitateurSurat = true;
    },
    async ajoutSuratToRecitateur() {
      this.suratRecitateur.suratId = this.surat;
      this.suratRecitateur.recitateurId = this.recitateurInfo.recitateurId;
      this.suratRecitateur.fileId = this.fileId;
      this.suratRecitateur.isLocked = this.model;
      const responseCategorie = await fetch(
        Constant.QORANI + "recitateur/addSuratLinkToRecitateur",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.suratRecitateur),
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout de la surat, veuillez réessayez plus tard, Merci."
        );
      }
    },
    handleAudioUpload(event) {
      this.showlowding_audio = true;
      const file = event.target.files[0];
      const apiEndpoint = `${Constant.QORANI}files/profile/pic`;
      const sourat = "Sourat";
      const customFormData = new FormData();
      customFormData.append("file", file);
      const contentTypeaudio = "audio/mpeg";
      customFormData.append("folderName", sourat);
      customFormData.append("fileType", contentTypeaudio);

      axios
        .post(apiEndpoint, customFormData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        })
        .then((response) => {
          // Handle the successful response
          console.log("Audio upload success:", response.data);
          this.fileId = response.data.id;
          this.showlowding_audio = false;
        })
        .catch((error) => {
          // Handle errors
          console.error("Error uploading audio:", error);
          this.showlowding_audio = false; // Ensure loading indicator is turned off in case of an error
        });
    },

    async UpdateRecitateur() {
      this.recitateur.name = this.recitateurInfo.name;
      this.recitateur.isTranslated = this.recitateurInfo.isTranslated;
      //this.recitateur.imgUrlId = this.recitateurInfo.imgId;
      this.recitateur.imgSansBackgroundUrlId = this.recitateurInfo.imgSansBackgroundUrlId;
      const responseCategorie = await fetch(
        Constant.QORANI + "recitateur/edit/" + this.recitateurInfo.recitateurId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.recitateur),
        }
      );

      try {
        this.$router.push({
          name: "Recitateur",
        });
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
        );
      }
    },
    uploadImageSuccess_BG(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`Recitateur/${newImageName}`);
      try {
        this.showlowding_BG = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage_BG = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrl_BG = url;
            this.imageInfo.fileName = imageName;
            this.imageInfo.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfo),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.recitateurInfo.imgSansBackgroundUrlId = responseData.id;
                this.showLoading_BG = false;
              } else {
                this.showLoading_BG = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoading_BG = false;
          }
        );
        this.showLoading_BG = false;
      } catch (error) {
        this.showlowding = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      this.showlowding = true;
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf("."));
      const file = this.convertImagePathToFile(fileList[index]);
      this.load = true;
      const themes = "Recitateur";
      const apiEndpoint = `${Constant.QORANI}files/profile/pic`;
      const customFormData = new FormData();
      const contentType =
        ext === ".jpeg" || ext === ".jpg" ? "image/jpeg" : "image/png";
      customFormData.append("fileType", contentType);
      customFormData.append("file", file);
      customFormData.append("folderName", themes);

      this.uploadPercentage = 0;

      axios
        .post(apiEndpoint, customFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the actual progress percentage
            const actualPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            this.fakePercentage = 0;
            this.uploadPercentage = 0;
            const timer = setInterval(() => {
              if (
                this.fakePercentage < actualPercentage &&
                this.fakePercentage < 90
              ) {
                this.fakePercentage++;
                this.uploadPercentage = this.fakePercentage;
              } else {
                clearInterval(timer);
              }
            }, 900);
          },
        })
        .then((response) => {
          this.recitateurInfo.imageUrl = response.data.downloadUrl;
          this.recitateurInfo.imgId = response.data.id;
          this.showlowding = false;
          this.uploadPercentage = 0;
          this.fakePercentage = 0;
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    },
    beforeRemove_BG(index, done, fileList) {
      this.showlowding_BG = false;
      this.fakePercentage_BG = 0;
      this.uploadPercentage_BG = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.images_BG.indexOf(fileList[index].path);
        this.recitateurInfo.imgSansBackgroundUrl = "";
        this.recitateurInfo.imgSansBackgroundUrlId = "";
        if (index > -1) {
          this.images_BG.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    beforeRemove(index, done, fileList) {
      this.showlowding = false;
      this.fakePercentage = 0;
      this.uploadPercentage = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.images.indexOf(fileList[index].path);
        this.recitateurInfo.imageUrl = "";
        this.recitateurInfo.imgId = "";
        if (index > -1) {
          this.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
  },
  async created() {
    this.isLoadingTables = true;
    this.suratArray = await getAllSurat();
    this.images.push({
      name: "",
      path: this.recitateurInfo.imageUrl,
      highlight: 1,
      default: 1,
    });
    this.images_BG.push({
      name: "",
      path: this.recitateurInfo.imgSansBackgroundUrl,
      highlight: 1,
      default: 1,
    });
    this.isLoadingTables = false;
  },
};
</script>
